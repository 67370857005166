<template>
    <div class="My-files-block-main-agflp">
    <div class="My-files-block-in-agflp">
        <div class="Muploaded-files-block-main-agflp">
            <div class="Muploaded-files-title-agflp">
                <h2>Mes fichiers téléversés</h2>
            </div>
            <div class="Muploaded-files-list-main-agflp" v-for="(file, key) in files" :key="key">
                <div class="Muploaded-files-list-r1-agflp">
                    <div class="Muploaded-files-name-agflp">
                        <h3>{{file.name}}</h3>
                    </div>
                    <!-- <div class="Muploaded-files-date-agflp">
                        <p>Téléversé le 01/12/2021</p>
                    </div> -->
                    <div class="Muploaded-files-btn-agflp">
                        <div class="Muploaded-files-btn-in-agflp">
                            <a href="#" v-on:click="download(file.path_attachment)" class="btn default-btnH40-aehp"><i class="fa-regular fa-file-pdf"></i> Télécharger</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'FilesDownload',
    computed: {
        ...mapState('files', ['files']),
    },
    methods: {
        ...mapActions('files', ['download']),
        download(path){
            this.$store.dispatch('files/download', path)
            .then((res) => {
                var a = document.createElement("a"); //Create <a>
                a.href = "data:image/png;base64," + res.bitmap; //Image Base64 Goes here
                a.download = res.name; //File name Here
                a.click(); //Downloaded file            
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }
}
</script>