<template>
  <div class="decl-welcome-block-aedecp">
    <div class="decl-welcome-block-in-aedecp">
      <div class="decl-welcome-box-aedecp decl-finish-box-aedecp">
        <div class="decl-welcome-icon-aedecp"><img alt="" src="~@/assets/images/finish_icon_aedecp.svg"></div>
        <h2>Votre déclaration est enregistrée.</h2>
        <p>Vous allez recevoir d’ici quelques instants un e-mail de confirmation ainsi que vos identifiants de connexion
          à votre dossier sinistre.</p>
        <div class="decl-welcome-btn-aedecp">
          <a class="btn default-btn-aehp default-Bborder-btn-aehp" href="#" v-on:click="redirect">Revenir à l’écran
            d’accueil</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepSix',
  methods: {
    redirect() {
      this.$router.push('/assure')
    }
  }

}
</script>
