<template>
	<div class="declaring-block-main-aedecp optiscroll stepTwo">
		<div class="declaring-block-in-aedecp">
			<div class="common-title-main-aehp common-title-left-aehp">
				<h2>Déclarant</h2>
			</div>
			<div class="declaring-form-main-aedecp">
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp errorLabel">Nom ou Raison sociale</div>
							<div class="defa-form-field-Tbox-aedecp">
								<input v-model="declarant.name" type="text" @change="(e) => remove(e.target)" placeholder="Lorem Ipsum" >
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">Adresse de la construction sinistrée</div>
							<div class="default-sub-form-row1-aedecp">
								<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp add-field1-aedecp">
									<div class="default-form-field1-Stit-aedecp">Rue</div>
									<div class="defa-form-field-Tbox-aedecp">
										<input v-model="declarant.street" @change="(e) => remove(e.target)" type="text" placeholder="Lorem Ipsum">
									</div>
								</div>
							</div>
							<div class="default-sub-form-row1-aedecp">
								<div class="col-lg-7 col-md-7 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W65-aedecp">
									<div class="default-form-field1-Stit-aedecp">Ville</div>
									<div class="defa-form-field-Tbox-aedecp">
										<input v-model="declarant.city" @change="(e) => remove(e.target)" type="text" placeholder="Lorem Ipsum" >
									</div>
								</div>
								<div class="col-lg-5 col-md-5 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W35-aedecp">
									<div class="default-form-field1-Stit-aedecp">Code postal</div>
									<div class="defa-form-field-Tbox-aedecp">
										<input type="text" v-model="declarant.zipcode" @change="(e) => remove(e.target)" placeholder="Lorem Ipsum" >
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-5 col-md-5 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W35-aedecp">
							<div class="default-form-field1-tit-aedecp">Téléphone</div>
							<div class="defa-form-field-Tbox-aedecp">
								<input type="text" v-model="declarant.phone_number" @change="(e) => remove(e.target)" placeholder="0622055767">
							</div>
						</div>
						<div class="col-lg-7 col-md-7 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W65-aedecp">
							<div class="default-form-field1-tit-aedecp">Email</div>
							<div class="defa-form-field-Tbox-aedecp">
								<input type="text" v-model="declarant.email" @change="(e) => remove(e.target)" placeholder="damien.chweudura@gmail.com">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="default-form-btn-bar-aedecp">
				<div class="default-form-btn-aedecp">
					<a href="#" v-on:click="nextStep"  class="btn default-btn-aehp">Continuer</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions} from 'vuex';
import $ from 'jquery';
export default {
	name: 'StepTwo',
	props: ['type'],
	data(){
		return{
			// declarant : {
			// 	name : null,
			// 	street : null,
			// 	city : null,
			// 	zipcode : null,
			// 	phone_number : null,
			// 	email : null
			// },
			declarant : {
				name : 'Alan',
				street : '8 place Msgr Rumeaux',
				city : 'Angers',
				zipcode : '49100',
				phone_number : "0649084987",
				email : "alan@azertyconsulting.fr"
			}
		}
	},
	methods: {
		...mapActions('createSinistre', ['addDeclarant']),
		nextStep(){
			if(this.type === 'assured'){
				let numberInputTwo = 0;
				let numberGoodInputTwo = 0;
				$( ".stepTwo input" ).each(function() {
					numberInputTwo++;
					if(!$(this).val()){
						$(this).addClass('errorInput');
					}
					else{
						numberGoodInputTwo++
						$(this).removeClass('errorInput');
					}
				});
				if(numberInputTwo === numberGoodInputTwo){
					this.addDeclarant(this.declarant);
					this.$emit('click', {action : 'StepThree'});
					console.log('RESET')
					numberInputTwo = 0;
					numberGoodInputTwo = 0;
				}
			}
			else{
				this.addDeclarant(this.declarant);
				this.$emit('click', {action : 'StepThree'});
			}
		},
		remove(e){
			$(e).removeClass('errorInput');
		}
	},
}
</script>
<style scoped>
.errorInput{
	border: solid 2px #f03737;
}
</style>