<template>
	<div class="decl-welcome-block-aedecp">
		<div class="decl-welcome-block-in-aedecp">
			<div class="decl-welcome-box-aedecp">
				<div class="decl-welcome-icon-aedecp"><img src="~@/assets/images/structural_icon_aedecp.svg"  alt=""></div>
				<h2>Vous êtes sur le point de commencer <span>votre déclaration de sinistre dommages-ouvrage.</span></h2>
				<div class="decl-welcome-btn-aedecp">
					<a v-on:click="redirect()" class="btn default-btn-aehp">Commencer</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'StepOne',
  methods: {
	redirect(){
		this.$emit('click', {action : 'StepTwo'});
	}
  }
  
}
</script>
