<template>
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 connexion-right-aelp">
        <div class="connexion-right-in-aelp">
            <div class="loader" style="text-align:center" v-if="loader">
                <div class="spinner-border text-primary" role="status" >
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div v-else class="connexion-box-main-aelp">
                <div class="common-title-main-aehp common-title-center-aehp">
                    <h2>Déclarer un sinistre</h2>
                    <p>Munissez-vous du votre numéro de contrat afin d’accèder à la déclaration de sinistre</p>
                </div>
                <div class="connexion-form-aelp">
                    <div id='contractInput' class="conn-form-field1-aelp">
                        <div class="conn-form-field1-tit-aelp">Numéro de contrat</div>
                        <div class="conn-form-field1-tbox-aelp">
                            <input type="text" placeholder="" v-model="contract_number">
                        </div>
                        <div class="conn-form-error-message-aelp"><i class="fa-solid fa-triangle-exclamation"></i>Veuillez renseigner une référence valide</div>
                    </div>
                    <div class="conn-form-submit-btn-aelp">
                        <button type="submit" v-on:click='find' class="btn default-btn-aehp">Se connecter</button>
						<p>2007CN/0196/0023</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import $ from 'jquery';
export default {
  name: 'CreateSinister',
  data(){
      return{
          contract_number : null,
          loader : false
      }
  },
  methods: {
		...mapActions('contract', ['findContract']),        
		find(){
          if(this.contract_number){
              this.loader = true;
                this.findContract(this.contract_number)
				.then(() => {
                    this.loader = false;
                    $('#contractInput').removeClass('conn-form-error-aelp');
					this.$router.push('/assure/declaration')
				})
				.catch(() => {
                    const promiseLoader = new Promise((resolve) => {
                        this.loader = false;
                        resolve(true);
                    })
                    promiseLoader.then(() => $('#contractInput').toggleClass('conn-form-error-aelp'))
                });
            }
          else{
            const promiseLoader = new Promise((resolve) => {
                this.loader = false;
                resolve(true);
            })
            promiseLoader.then(() => $('#contractInput').toggleClass('conn-form-error-aelp'))
        }
      }
  }
  
}
</script>