<template>
	<div class="tbar-search-box-dbp search-bar-open-dbp">
		<ais-instant-search :search-client="searchClient" index-name="staging" placeholder="Recherche">
			<ais-search-box :class-names="{'ais-SearchBox-reset' : 'none'}">
				<template v-slot:reset-icon></template>
			</ais-search-box>
			<ais-hits>
			<template v-slot="{ items }">
				<ul>
				<li v-for="item in items" :key="item.objectID" class="itemSearch" @click="redirect(item.id)">
					<p>Sinistre : <strong>{{item.sinister_reference}}</strong></p>
					<p>Contrat : <strong>{{item.contract}}</strong></p>
					<p>Déclarant : <strong>{{ item.name }}</strong></p>
				</li>
				</ul>
			</template>
			</ais-hits>
		</ais-instant-search>
	</div>
	<div class="tbar-search-icon-dbp"><a @click="openSearch()" href="#" id="search_icon_dbp"><i class="fas fa-search"></i></a></div>
</template>

<script>
// @ is an alias to /src
import algoliasearch from 'algoliasearch/lite';
import $ from 'jquery';
export default {
  name: 'search',
  data() {
    return {
      searchClient: algoliasearch(
        'PJXY8EN622',
        'a9a358c71e1767e3a6e3b1306f5a3f44'
      ),
    };
  },
  methods:{
		openSearch(){
			$('.ais-SearchBox-submit').hide();
			$('.ais-SearchBox-reset').hide();
			$(".tbar-search-main-dbp").toggleClass("search-bar-open-dbp");
		},
		redirect(id){
			this.$router.push(`/gestionnaire/sinistre/details/${id}`);
		}
  }
}
</script>
<style scoped>
.ais-Hits{
	z-index: 9;
	background: white;
	border-radius: 31px;
	position: relative;
	box-shadow: 0px 0px 8px #76dcf4;
	padding: 10px;
	padding-top: 30px;
	max-height: 300px;
	overflow-Y: scroll;
}
.tbar-search-box-dbp input{
	position: relative;
	z-index: 99;
}
.ais-Hits-item::marker{
	display: none!important;
}
.searchContent{
	padding: 5px 0;
}
.itemSearch{
	margin : 10px;
	box-shadow: inset 0px 1px 0px #e4e8f4;
	padding: 3px;
	cursor: pointer;
}
.itemSearch:first-child {
    box-shadow: inset 0px 0px 0px #e4e8f4;
}
strong{
	font-weight: 600;
}
.none{
	display: none!important;
}
</style>
