<template>
<div class="wrapper">
<!-- CONTAIN_START -->
    <section id="contain">
        <div class="sidebar-main-aedecp sidebar-main-aehp">
            <div class="logo-main-aedecp"><a href="#"><img src="~@/assets/images/logo_main_white_hp.svg" alt=""></a></div>
            <div class="sidebar-content-aedecp optiscroll">
                <div class="sidebar-content-in-aedecp">
                    <div class="menu-main-aehp">
                        <ul>
                            <li><!-- Add Class "active" -->
                                <a href="#" v-on:click="this.$router.push('/assure/dashboard')">
                                    <div class="menu-main-icon-aehp">
                                        <i class="fa-regular fa-house-crack"></i>
                                    </div>
                                    <div class="menu-main-text-aehp">Mon Sinistre</div>
                                </a>
                            </li>
                            <li class="active" v-if="iban">
                                <a href="#">
                                    <div class="menu-main-icon-aehp">
                                        <i class="fa-regular fa-bank"></i>
                                    </div>
                                    <div class="menu-main-text-aehp">Mon IBAN</div>
                                </a>
                            </li>
                            <li>
                                <a href="#" v-on:click="this.$router.push('/assure/dashboard/fichiers')">
                                    <div class="menu-main-icon-aehp">
                                        <i class="fa-regular fa-copy"></i>
                                    </div>
                                    <div class="menu-main-text-aehp">Mes Fichiers</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="declaration-close-aedecp"><a href="#" v-on:click="logOut"><i class="fa-regular fa-sign-out"></i>Se déconnecter</a></div>
            </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 content-main-aedecp content-main-aehp">
            <div class="top-bar-main-aedecp">
                <div class="tbar-left-aedecp">
                    <div class="logo-main-mobile-aedecp"><a href="#"><img src="~@/assets/images/logo_main_hp.svg" alt=""></a></div>
                    <div class="tbar-user-info-main-aedecp">
                        <div class="tbar-user-icon-aedecp">
                            <div class="tbar-user-icon-in-aedecp">
                                <a href="#"><img src="~@/assets/images/QBE_logo_aedecp.svg" alt=""></a>
                            </div>
                        </div>
                        <div class="tbar-user-text-aedecp">
                            <h2>QBE</h2>
                            <p>Contrat n° <span>{{contract_number}}</span></p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="tbar-right-aedecp">
                    <div class="Message-btn-aehp">
						<a href="javascript:void(0);" v-on:click="openMessage" id="openMessage" class="btn default-btnH40-aehp default-Bborder-btn-aehp"><i class="fa-regular fa-envelope-open"></i> <span>Messagerie</span></a>
                    </div>
                    <div class="mobile-menu-icon-aedecp">
                        <div class="menu-toggle-btn-aedecp">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <Add v-if="add" />
            <Done v-if="done" />
        </div>
        <div class="clearfix"></div>
    </section>
    <!-- CONTAIN_END -->

    <!-- ADD_USER_START -->
	<div class="message-overlay-aehp"></div>
	<div class="message-sidebar-main-aehp" id="message_sidebar">
		<div class="message-sidebar-title-aehp">
			<h2>Messagerie</h2>
			<div class="message-close-aehp" v-on:click="closeMessage()">
				<a href="javascript:void(0)"><i class="fa-solid fa-xmark"></i></a>
			</div>
		</div>
		<div class="message-sidebar-bot-aehp">
			<div class="messaging-block-main-aehp">
				<div class="message-chat-main-aehp optiscroll" id="scrollBottom">
                    <Messages :id="idSinister" v-if="refreshMessage" :key="messageSend" profil="insured" />
				</div>
				<div class="message-chat-Tbox-main-aehp">
					<div class="message-chat-Tbox-in-aehp">
						<div class="message-chat-Tbox-box-aehp">
							<textarea v-model="messageContent" cols="1" rows="1" placeholder="Ecrire un message à votre conseiller" ></textarea>
							<input type="submit" v-on:click="sendMessage" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    </div>
    <!-- ADD_USER_END -->
</template>

<script>
import {mapActions,mapState} from 'vuex';
import Add from '@/components/assured/iban/Add.vue'
import Done from '@/components/assured/iban/Done.vue'
import Messages from '@/components/administrator/Messages.vue';
import $ from 'jquery';

export default {
	name: 'Iban',
	data(){
		return{
            add : false,
            done : false,
			refreshMessage : false,
			messageSend : 0,
			idSinister : null,
            messageContent : null,
		};
	},
    components: {
        Add,
        Done,
		Messages
	},
    computed:{
        ...mapActions('iban', ['find']),
        ...mapState('iban', ['contract_number', 'iban']),
    },
    beforeMount(){
        this.$store.dispatch('iban/find')
        .then((res) => {
			this.idSinister = res.id
            if(res.iban === true){
                this.add = true;
            }
            else if(res){
                this.done = true;
            }
        })
        .catch(err => console.log(err))
    },
	methods: {
        logOut(){
            localStorage.removeItem('jwt');
            this.$router.push('/assure');
        },
		openMessage(){
            $(".message-overlay-aehp").fadeToggle();
            $("#message_sidebar").toggleClass("open");
            this.messageSend = this.messageSend +1;
            this.refreshMessage = true;
            this.$store.commit('dashboardSinister/UPDATE_MESSAGES', 0)
        },
		closeMessage(){
            $(".message-overlay-aehp").fadeOut();
            $("#message_sidebar").removeClass("open");
        },
        sendMessage(){
            const token = localStorage.getItem('jwt');
            this.$store.dispatch('adminMessage/send', {idSinister: this.idSinister, message : this.messageContent, token, from : 'insured'})
            .then(() => {
                this.messageSend = this.messageSend +1;
                this.messageContent = null;
            })
        },
	}
}
</script>
<style scoped>
</style>
