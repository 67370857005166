<template>
  <div class="content-in-dbp declaring-block-main-aedecp optiscroll">
    <div class="declaring-block-in-aedecp">
      <div class="title-main-dbp">
        <h2>Tableau de bord</h2>
      </div>
      <div class="contracts-block-main-dbp">
        <div class="contract-info-box-main-dbp">
          <router-link :to="{name: 'administrator-page', params: {page: 'sinistres'}}">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12 contract-info-box1-dbp">
              <div class="contract-info-box1-in-dbp">
                <div class="contr-info-box-icon-main-dbp">
                  <div class="contr-info-box-icon-dbp">
                    <i class="far fa-house-chimney-crack"></i>
                  </div>
                </div>
                <div class="contr-info-box-text-dbp">
                  <p>Nouveaux <br>sinistres</p>
                  <div class="contr-info-box-number-dbp">{{ data.new }}</div>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
              :to="{name: 'filter-sinisters', params: {page: 'sinistres', filter: 'Creglement'}, query: {'menu[big_category]': 'Creglement'}}">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12 contract-info-box1-dbp contract-info-box-green-dbp">
              <div class="contract-info-box1-in-dbp">
                <div class="contr-info-box-icon-main-dbp">
                  <div class="contr-info-box-icon-dbp">
                    <i class="far fa-wallet"></i>
                  </div>
                </div>
                <div class="contr-info-box-text-dbp">
                  <p>Sinistres en cours <br>de réglement</p>
                  <div class="contr-info-box-number-dbp">{{ data.paiement }}</div>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
              :to="{name: 'filter-sinisters', params: {page: 'sinistres', filter: 'Dmessage'}, query: {'menu[big_category]': 'Dmessage'}}">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12 contract-info-box1-dbp contract-info-box-yellow-dbp">
              <div class="contract-info-box1-in-dbp">
                <div class="contr-info-box-icon-main-dbp">
                  <div class="contr-info-box-icon-dbp">
                    <i class="far fa-envelope-open"></i>
                  </div>
                </div>
                <div class="contr-info-box-text-dbp">
                  <p>Messages <br>à traiter</p>
                  <div class="contr-info-box-number-dbp">{{ data.message }}</div>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
              :to="{name: 'filter-sinisters', params: {page: 'sinistres', filter: 'Bactive'}, query: {'menu[big_category]': 'Bactive'}}">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12 contract-info-box1-dbp contract-info-box-black-dbp">
              <div class="contract-info-box1-in-dbp">
                <div class="contr-info-box-icon-main-dbp">
                  <div class="contr-info-box-icon-dbp">
                    <i class="far fa-house-chimney-crack"></i>
                  </div>
                </div>
                <div class="contr-info-box-text-dbp">
                  <p>Sinistres déclarés <br>en cours</p>
                  <div class="contr-info-box-number-dbp">{{ data.notClosed }}</div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- <div class="sinistres-block-main-dbp">
          <div class="sinistres-block-in-dbp">
              <div class="sinistres-box-title-aehp">
                  <h3>Sinistres</h3>
                  <p>Par semaine/trimestre</p>
              </div>
              <div class="sinistres-chart-box-dbp">
                  <img src="~@/assets/images/chart_img_dbp.svg" alt="">
              </div>
          </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
      data: {
        new: null,
        paiement: null,
        notClosed: null,
        message: null
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch('dashboardAdmin/find')
        .then((res) => {
          this.data = res;
        })
  }

}
</script>
