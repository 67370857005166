<template>
    <div class="declaring-block-main-aedecp optiscroll">
        <div class="declaring-block-in-aedecp">
            <div class="common-title-main-aehp">
                <h2>Mon IBAN</h2>
                <div class="common-title-border-aehp"></div>
                <p>Veuillez renseigner vos coordonnées bancaires afin d’obtenir le paiement de vos indemnités suite à votre sinistre.</p>
            </div>
            <div class="IBAN-block-main-agribp">
                <div class="IBAN-block-in-agribp">
                    <div class="IBAN-form-main-agribp">
                        <div class="default-form-field1-aedecp">
                            <div class="default-form-field1-in-aedecp">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                    <div class="default-form-field1-tit-aedecp">Nom</div>
                                    <div class="defa-form-field-Tbox-aedecp">
                                        <input type="text" placeholder="Chweudura" v-model="bank.lastname">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                    <div class="default-form-field1-tit-aedecp">Prénom</div>
                                    <div class="defa-form-field-Tbox-aedecp">
                                        <input type="text" placeholder="Damien" v-model="bank.firstname">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="default-form-field1-aedecp"><!-- FOR CORRECT FIELD "default-form-field1-correct-aedecp" -->
                            <div class="default-form-field1-in-aedecp">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                    <div class="default-form-field1-tit-aedecp">IBAN</div>
                                    <div class="defa-form-field-Tbox-aedecp">
                                        <input type="text" placeholder="" v-model="bank.iban">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="default-form-field1-aedecp ">
                            <div class="default-form-field1-in-aedecp">
                                <div class="col-lg-4 col-md-4 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                    <div class="default-form-field1-tit-aedecp">BIC</div>
                                    <div class="defa-form-field-Tbox-aedecp">
                                        <input type="text" placeholder="" v-model="bank.bic">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="IBAN-form-btn-aeribp">
                            <button type="submit" class="btn default-btnH48-aehp default-green-btn-aehp" data-toggle="modal" data-target="#Validate_IBAN">Valider mon IBAN</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <!-- VALIDATE_IBAN_MODAL_START -->
            <div class="IBAN-modal-main-aehp modal fade" id="Validate_IBAN" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="IBAN-Minfo-main-aeribp">
                                <div class="IBAN-Minfo-title-aeribp">
                                    <div class="IBAN-Minfo-tit-icon-aeribp"><i class="fa-regular fa-circle-check"></i></div>
                                    <h2>Confirmation de vos informations bancaires</h2>
                                </div>
                                <div class="IBAN-Minfo-text-aeribp">
                                    <div class="IBAN-Minfo-note-aeribp">Après validation de vos informations bancaires, vous ne pourrez plus les modifier.</div>
                                    <div class="IBAN-Minfo-list-aeribp">
                                        <div class="IBAN-Minfo-data1-aeribp">
                                            <div class="IBAN-Minfo-data-tit-aeribp">IBAN</div>
                                            <div class="IBAN-Minfo-data-text-aeribp">{{bank.iban}}</div>
                                        </div>
                                        <div class="IBAN-Minfo-data1-aeribp">
                                            <div class="IBAN-Minfo-data-tit-aeribp">BIC</div>
                                            <div class="IBAN-Minfo-data-text-aeribp">{{bank.bic}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer IBAN-Mfooter-aeribp">
                            <div class="IBAN-Mfooter-left-aeribp IBAN-Mfooter-btn-aeribp">
                                <button type="button" class="btn default-btnH40-aehp default-Yborder-btn-aehp" data-dismiss="modal">Modifier l’IBAN</button>
                            </div>
                            <div class="IBAN-Mfooter-right-aeribp IBAN-Mfooter-btn-aeribp">
                                <button type="button" v-on:click="addIban" class="btn default-btnH40-aehp default-yellow-btn-aehp" data-dismiss="modal"><i class="fa-regular fa-circle-check"></i>Confirmer</button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- VALIDATE_IBAN_MODAL_END -->
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'Add',
    data(){
        return{
            bank : {
                lastname : 'Doe',
                firstname : 'John',
                iban : 'FR7630001007941234567890185',
                bic : '30001'
            }
        }
    },
    methods: {
        ...mapActions('iban', ['createIban']),
        addIban(){
            this.createIban(this.bank)
            .then(()=>{
                this.$router.push('/assure/dashboard')
            })
            .catch(err => console.log(err))
        },
        redirect : function(action){
            this.$emit('click', {action});
        }
    }
}
</script>