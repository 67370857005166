<template>
    <div class="content-in-dbp user-page-up">
        <div class="user-top-up">
            <div class="user-top-left-up title-main-dbp">
                <h2>Experts</h2>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="loader" v-if="!departments">
            <div class="spinner-border text-primary" role="status" >
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="user-bottom-up optiscroll">
            <div class="user-bottom-in-up">
                <div class="user-info-up">
                    <div class="expers-list-ep" v-for="(dep,key) in departments" :key="key">
                        <div class="expers-name-ep">
                            <div class="expers-name-text-ep"><span>{{dep.number}}</span>{{dep.name}}</div>
                        </div>
                        <div class="expers-text-ep"></div>
                        <div class="user-action-up">
                            <div class="user-action-in-up">
                                <div class="user-edit-up"><a href="javascript:void(0)" id="openExpersUser" v-on:click="openExperts(dep)"><i class="far fa-edit"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ADD_USER_START -->
        <div class="rcd-overlay-rcp" v-on:click="closeExperts"></div>
        <div class="rcd-information-rcp" id="Experts_user_ep">
            <div class="rcd-close-rcp"  v-on:click="closeExperts">
                <a href="javascript:void(0)"><i class="fal fa-times"></i></a>
            </div>
            <div class="rcd-info-box-rcp optiscroll">
                <div class="rcd-info-box-in-rcp">
                    <div class="rcd-info-box-pad-rcp">
                        <div class="rcd-title-rcp rcd-title-black-rcp">
                            <h2>{{dep.number}} - {{dep.name}}</h2>
                        </div>
                        <div class="rdc-speaker-rcp">
                            <div class="error-info-box-ep" style="display:none">
                                <p>Vous devez renseigner au moins un expert par département</p>
                            </div>
							<div class="dos-info-field-label-dp">Expert N°1 <strong>*</strong></div>
							<div class="contentSelect" v-on:click="(e) => openSelect(e.target, e)">
								<p>{{expert1.name}}</p>
							</div>
							<div class="dataSelect">
								<div class="elementSelect" :id="key" :style="expert.id%2 == 0 ? 'background:#F0FCFF' : 'background:none'" :class="expert1.id === expert.id || expert2.id === expert.id || expert3.id === expert.id ? 'disabled' : 'none' "  v-for="(expert,key) in experts" :key="key" @click="(e) => updateSelectedExperts(1, key, e.target)">
									<p>{{expert.name}}</p>
								</div>
							</div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Adresse e-mail - Expert N°1 <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" v-model="expert1.email">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
										<div class="dos-info-field-label-dp">Expert N°2 <strong>*</strong></div>
										<div class="contentSelect" v-on:click="(e) => openSelect(e.target, e)">
											<p>{{expert2.name}}</p>
										</div>
										<div class="dataSelect">
											<div class="elementSelect" :id="key" :style="expert.id%2 == 0 ? 'background:#F0FCFF' : 'background:none'" :class="expert1.id === expert.id || expert2.id === expert.id || expert3.id === expert.id ? 'disabled' : 'none' "  v-for="(expert,key) in experts" :key="key" @click="(e) => updateSelectedExperts(2, key, e.target)">
												<p>{{expert.name}}</p>
											</div>
										</div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Adresse e-mail - Expert N°2 <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" v-model="expert2.email">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
										<div class="dos-info-field-label-dp">Expert N°3 <strong>*</strong></div>
										<div class="contentSelect" v-on:click="(e) => openSelect(e.target, e)">
											<p>{{expert3.name}}</p>
										</div>
										<div class="dataSelect">
											<div class="elementSelect" :id="key" :style="expert.id%2 == 0 ? 'background:#F0FCFF' : 'background:none'" :class="expert1.id === expert.id || expert2.id === expert.id || expert3.id === expert.id ? 'disabled' : 'none' " v-for="(expert,key) in experts" :key="key" @click="(e) => updateSelectedExperts(3, key, e.target)">
												<p>{{expert.name}}</p>
											</div>
										</div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Adresse e-mail - Expert N°3 <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" v-model="expert3.email">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rcd-info-bot-bar-rcp">
                <div class="rcd-info-bot-left-rcp">
                    <div   v-on:click="closeExperts" class="cancel-btn-main-hp"><a href="#" class="btn default-btnH40-aehp default-Gray-border-btn-aehp">Annuler</a></div>
                </div>
                <div class="rcd-info-bot-right-rcp">
                    <div class="confirm-btn-main-hp"><a  v-on:click="registerExpert" href="javascript:void(0)" class="btn default-btnH40-aehp default-green-btn-aehp"><i class="far fa-floppy-disk"></i>Enregistrer</a></div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- ADD_USER_END -->
        <!-- UPDATED_RCD_START -->
        <div class="green-box-main-top-dp">
            <div class="green-box-main-dp" style="display:none">
                <div class="error-text-lp"><i class="far fa-check-circle"></i>Enregistrement effectué</div>
                <div class="error-box-close-lp"><a href="javascript:void(0)"><i class="fal fa-times"></i></a></div>
            </div>
        </div>
    <!-- UPDATED_RCD_END -->
    </div>
</template>
<script>
import $ from 'jquery';
export default {
    name: 'Experts',
    data(){
        return{
            departments : null,
            dep : {
                id : null,
                name : null,
                number : null
            },
            experts : null,
            expert1 : {
                name : null,
                email : ''
            },
            expert2 : {
                email : null
            },
            expert3 : {
                email : null
            },
            box : null
        }
    },
    beforeCreate(){
        this.$store.dispatch('experts/findDepartments')
        .then((res) => {
            this.departments = res;
            this.$store.dispatch('experts/findExperts')
            .then((res) => {
                this.experts = res;
            });
        });
    },
    methods:{
		openSelect(target){
			$('.dataSelect').slideUp();
			if($(target).hasClass('contentSelect')){
				$(target).next().stop().slideToggle('fast');
			}
			else{
				$(target).parent().next().stop().slideToggle('fast');
			}
		},
        openExperts(dep){
            console.log(dep)
            this.$store.dispatch('experts/findExpertsDep', {id : dep.id})
            .then((res) => {
                this.dep.name = dep.name;
                this.dep.number = dep.number;
                this.dep.id = dep.id;
                this.expert1 = {
                    name : null,
                    email : null
                },
                this.expert2 = {
                    email : null
                },
                this.expert3 = {
                    email : null
                }
                if(res){
                    for (const expert of res) {
                        switch(expert.importance){
                            case(1):
                                this.expert1 = expert.id_expert;
								this.expert1.email = expert.email
                                break;
                            case(2):
                                this.expert2 = expert.id_expert;
								this.expert2.email = expert.email
                                break;
                            case(3):
                                this.expert3 = expert.id_expert;
								this.expert3.email = expert.email
                                break;
                        }
                    }
                }
                $(".rcd-overlay-rcp").fadeToggle();
                $("#Experts_user_ep").toggleClass("open");
            })

        },
        closeExperts(){
            $(".rcd-overlay-rcp").fadeOut();
            $("#Experts_user_ep").removeClass("open"); 
        },
		updateSelectedExperts(number, index, e){
			if(!$(e).hasClass('disabled')){
				$('.dataSelect').slideUp();
				console.log(this.experts[index])
				switch(number){
					case(1):
						this.expert1 = this.experts[index]
						break;
					case(2):
						this.expert2 = this.experts[index]
						break;
					case(3):
						this.expert3 = this.experts[index]
						break;
				}
			}
		},
        // updateExpert(expert, number){
		// 	var classs = $(expert.target).attr('class');
		// 	console.log('------',classs);
		// 	$('.dataSelect').slideUp();
        //     var id = $(expert.target).children(":selected").attr("id");
        //     if(number === 1){
        //         this.expert1 = this.experts[id];
        //     }
        //     else if(number === 2){
        //         this.expert2 = this.experts[id];
        //     }
        //     else if(number === 3){
        //         this.expert3 = this.experts[id];
        //     }
        //     console.log(this.experts[id])
        // },
        registerExpert(){
            if(this.expert1.name === null){
                console.log('toto');
                $('#expert1').toggleClass('dos-info-error-field-dp');
                $('.error-info-box-ep').fadeIn();
            }else{
                $('#expert1').removeClass('dos-info-error-field-dp');
                $('.error-info-box-ep').fadeOut();
                this.$store.dispatch('experts/register', {dep : this.dep, experts : {1 : this.expert1, 2 : this.expert2, 3 : this.expert3}})
                .then(() => {
                    $(".green-box-main-dp").fadeIn();
                    this.box = 	setTimeout(function(){ 
                        $(".green-box-main-dp").fadeOut();
                    }, 3000);
                    this.closeExperts();
                });
            }
        }
    }
}
</script>

<style scoped>
.content-in-dbp{
    overflow: scroll;
}
.loader{
    width: 100%;
    display: flex;
    justify-content: center;
}
.contentSelect {
    border: 2px solid #E4E8F4;
    border-radius: 12px;
    width: 100%;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
    line-height: 19px;
    height: 56px;   
	padding: 18px 50px 10px 6px;
    transition: all 0.3s linear;
    background: url(/img/dropdown_arrow.94f17355.svg) no-repeat right 24px center;
	cursor: pointer
}
.dataSelect{
	border: 2px solid #E4E8F4;
    border-radius: 12px;
    width: 100%;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
    line-height: 19px;
    max-height: 250px;
	overflow-y: scroll;
    transition: all 0.3s linear;
	cursor: pointer;
}
.contentSelect p{
	padding: 10px 15px 0px 15px;
    background-position: right 17px center;
}
.contentSelect:hover{
	box-shadow: 0px 0px 0px 4px #daf5fb;
    border-color: #76DCF4;
}
.elementSelect{
	padding: 18px 18px;
}
.dataSelect{
	display: none;
}
.disabled{
	position: relative;
	z-index: 99;
}
.disabled::after{
	content: '';
	position: absolute;
	background: #7979792a;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999;
}
</style>