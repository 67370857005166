<template>
    <p v-for="(file, key) in fileToUpload" :key="key" >Veuillez déposer le fichier {{file.type_attachment}} ci-dessous</p>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'Ask',
    computed: {
        ...mapState('files', ['fileToUpload']),
    },
    methods: {
    }
}
</script>