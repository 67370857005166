<template>
	<div class="defa-file-upload-main-aedecp">
		<div class="defa-Fupload-drag-area-aedecp drag-area" @change="change" @dragover="dragover" @dragleave="dragleave" @drop="drop">
			<div class="defa-Fupload-drag-icon-aedecp"><i class="fa-regular fa-file-upload"></i></div>
			<h3>Glisser et déposer vos fichiers ici</h3>
			<p>Fichiers supportés : PDF, Jpeg, PNG, BMP</p>
			<button class="btn default-btnH40-aehp">Parcourir</button>
			<input type="file" hidden>
			<div class="defa-Fupload-drag-note-aedecp"> La taille du fichier ne doit pas dépasser 10 Mo</div>
		</div>
		<div class="render">
			<div v-for="item,key in images" :key="key" class="picture">
				<div class="defa-Fupload-list-main-aedecp">
					<div class="col-lg-3 defa-upload-file-box1-aedecp">
						<div class="defa-ULfile-box-icon-aedecp"><i class="fa-regular fa-file-image"></i></div>
						<div class="defa-ULfile-box-info-aedecp">
							<div class="defa-ULfile-box-close-aedecp" v-on:click="deleteImage(key)"><a href="#"><i class="fa-regular fa-circle-xmark"></i></a></div>
							<div class="defa-ULfile-name-aedecp">{{item.name}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DragAndDrop',
	data(){
		return {
			images:[],
		};
	},
	mounted(){
		const dropArea = document.querySelector(".drag-area"),
		button = dropArea.querySelector("button"),
		input = dropArea.querySelector("input");

		button.onclick = ()=>{
			input.click(); //if user click on the button then the input also clicked
		}
	},
	methods:{
		change(){
			const dropArea = document.querySelector(".drag-area");
			const input = dropArea.querySelector("input");
			let file = input.files[0];
			dropArea.classList.add("active");
			const images = this.images;
            const emitImages = this.$emit

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                images.push({name : file.name, state : '0', buffer : reader.result, type : file.type.split('/')[1]});
                emitImages('updateImages', {images});
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
		},
		dragover(event){
				const dropArea = document.querySelector(".drag-area");
				event.preventDefault(); //preventing from default behaviour
				dropArea.classList.add("active");
		},
		dragleave(){
			const dropArea = document.querySelector(".drag-area");
			dropArea.classList.remove("active");
		},
		drop(event){
				event.preventDefault(); //preventing from default behaviour
				const file = event.dataTransfer.files[0];
				const images = this.images;
                const emitImages = this.$emit;

                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    console.log(reader.result);
                    images.push({name : file.name, state : '0', buffer : reader.result, type : file.type.split('/')[1]});
                    emitImages('updateImages', {images});
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
				// var reader = new FileReader();
				// reader.readAsArrayBuffer(file);
				// reader.onloadend = function(){
                //     console.log(binary);
				// }
		},
		deleteImage(index){
			this.images.splice(index,1);
			this.$emit('updateImages', {images : this.images});
		}
	}

}
</script>
