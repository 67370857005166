<template>
        <div class="declaring-block-main-aedecp optiscroll">
            <div class="declaring-block-in-aedecp">
                <div class="common-title-main-aehp">
                    <h2>Mon IBAN</h2>
                    <div class="common-title-border-aehp"></div>
                </div>
                <div class="IBAN-block-main-agribp">
                    <div class="IBAN-block-in-agribp">
                        <div class="IBAN-details-box-main-agribp">
                            <div class="IBAN-details-box-in-agribp">
                                <div class="IBAN-box-title-agribp">
                                    <div class="IBAN-box-tit-icon-agribp"><i class="fa-regular fa-bank"></i></div>
                                    <h2>{{iban.firstname}} {{iban.lastname}}</h2>
                                </div>
                                <div class="IBAN-detail-info-main-agribp">
                                    <div class="IBAN-detail-info-r1-agribp">
                                        <!-- <div class="col-lg-8 col-md-6 col-sm-6 col-12 IBAN-Dinfo-col1-agribp">
                                            <div class="IBAN-Dinfo-title-agribp">TITULAIRE</div>
                                            <div class="IBAN-Dinfo-text-agribp">{{iban.firstname}} {{iban.lastname}}</div>
                                        </div> -->
                                        <!-- <div class="col-lg-4 col-md-6 col-sm-6 col-12 IBAN-Dinfo-col1-agribp">
                                            <div class="IBAN-Dinfo-title-agribp">PAYS DE LA BANQUE</div>
                                            <div class="IBAN-Dinfo-text-agribp">FRAN** ???</div>
                                        </div> -->
                                    </div>
                                    <div class="IBAN-detail-info-r1-agribp">
                                        <div class="col-lg-8 col-md-8 col-sm-12 col-12 IBAN-Dinfo-col1-agribp">
                                            <div class="IBAN-Dinfo-title-agribp">IBAN</div>
                                            <div class="IBAN-Dinfo-text-agribp">{{(iban.iban).substr(0,4)}} **** **** **** **** **** {{(iban.iban).substr(iban.iban.length - 4)}} </div>
                                        </div>
                                    </div>
                                    <div class="IBAN-detail-info-r1-agribp">
                                        <div class="col-lg-8 col-md-8 col-sm-12 col-12 IBAN-Dinfo-col1-agribp">
                                            <div class="IBAN-Dinfo-title-agribp">BIC</div>
                                            <div class="IBAN-Dinfo-text-agribp">{{iban.bic}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: 'Done',
    data(){
        return{
        }
    },
    computed:{
        ...mapState('iban', ['contract_number', 'iban']),
    },
    methods: {
        ...mapActions('iban', ['createIban']),
        ...mapState('iban', ['contract_number', 'iban']),
        addIban(){
            this.createIban(this.bank)
            .then(()=>{
                this.$router.push('/assure/dashboard')
            })
            .catch(err => console.log(err))
        },
        redirect : function(action){
            this.$emit('click', {action});
        }
    }
}
</script>