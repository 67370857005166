<template>
    <div class="message-chat-in-aehp" v-for="(message,key) in messages" :key="key">
        <div class="message-box-row1-aehp" v-if="message.from === profil">
            <div class="message-box-aehp">
                <div class="message-info-box-aehp">{{message.content}}</div>
                <div class="clearfix"></div>
                <div class="message-data-aehp">
                    <p>Vous - {{message.date_created}}</p>
                </div>
            </div>
        </div>
        <div class="message-box-row1-aehp" v-else>
            <div class="message-box-aehp message-partner-aehp">
                <div class="message-info-box-aehp">{{message.content}}</div>
                <div class="clearfix"></div>
                <div class="message-data-aehp">
                    <p v-if="message.from === 'administrator'">Votre conseiller - {{message.date_created}}</p>
                    <p v-else>déclarant - {{message.date_created}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vuecookie from 'vue-cookie'
export default {
    name: 'Messages',
    props : ['id', 'profil'],
    data(){
        return{
            messages : null,
            scroll : null,
        }
    },
    beforeCreate(){
        const token = vuecookie.get('jwt');
		const local = localStorage.getItem('jwt');
        this.$store.dispatch('adminMessage/find', {idSinister : this.id, token : token ? token : local, profil : this.profil})
        .then((res) => {
            console.log(res);
            this.messages = res;
            this.scrollBottom();
        })
    },
    methods:{
        scrollBottom(){
            this.scroll = setInterval(() => {
                var container = document.querySelector("#scrollBottom");
                if(container){
                    container.scrollTop = container.scrollHeight;
                    this.stopInterval()
                }
            }, 100);
        },
        stopInterval(){
            clearInterval(this.scroll)
        }

    }
}
</script>