<template>
	<div class="wrapper">
	<!-- HEADER_START -->
		<header id="header" class="header-login-main-aelp">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-12 header-login-in-aelp">
						<div class="header-left-aelp">
							<div class="logo-aelp"><a href="#"><img src="~@/assets/images/logo_main_hp.svg" alt=""></a></div>
							<div class="head-back-link-aelp">
								<a v-on:click="redirect({action : 'choice'})" class="default-link-aelp" style="cursor:pointer"><i class="fa-regular fa-arrow-left-long"></i>Retour</a>
							</div>
						</div>
						<div class="header-right-aelp">
							<div class="head-contact-btn-aelp">
								<a href="#" class="btn default-btn-aehp default-Bborder-btn-aehp">Contact</a>
							</div>
							<div class="clearfix"></div>
						</div> 
						<div class="clearfix"></div>
					</div>
				</div>
			</div>
		</header>
	<!-- HEADER_END -->
	<!-- CONTAIN_START -->
		<section id="contain">
			<div class="connexion-block-main-aelp">
				<div class="container-fluid">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 connexion-block-in-aelp">
							<div class="col-lg-6 col-md-6 col-sm-12 col-12 connexion-left-aelp">
								<div class="connexion-banner-aelp"></div>
							</div>
                            <Choice v-if="choice" v-on:click="redirect" />
                            <FindSinister v-if="find" />
                            <CreateSinister v-if="declare" />
							<div class="clearfix"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</section>
	<!-- CONTAIN_END -->
	</div>
</template>

<script>
import Choice from '../../components/assured/Choice.vue';
import CreateSinister from '../../components/assured/CreateSinister.vue';
import FindSinister from '../../components/assured/FindSinister.vue';

export default {
    name: 'Assure',
    data(){
        return{
            choice : true,
            find : false,
            declare : true,
        };
    },
    components: {
        Choice,
        FindSinister,
        CreateSinister
    },
    methods: {
        redirect(action){
            switch(action.action){
                case 'declare' :
                    this.choice = false;
                    this.find = false;
                    this.declare = true;
                    break;
                case 'find' :
                    this.choice = false;
                    this.declare = false;
                    this.find = true;
                    break;
                case 'choice' :
                    this.declare = false;
                    this.find = false;
                    this.choice = true;
                    break;
            }
        }
    }
}
</script>
<style scoped>
	.connexion-banner-aelp{
		background: url('~@/assets/images/connexion_banner_aelp.png') no-repeat center center;
		background-size: cover ;
	}
</style>
