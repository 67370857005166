<template>
  <div class="wrapper">
    <!-- CONTAIN_START -->
    <section id="contain">
      <div class="sidebar-main-aedecp">
        <div class="logo-main-aedecp"><a href="#"><img alt="" src="~@/assets/images/logo_main_white_hp.svg"></a></div>
        <div class="sidebar-content-aedecp optiscroll">
          <div class="sidebar-content-in-aedecp">
            <div class="decl-steps-main-aedecp">
              <ul>
                <li :class="stepOneData.state">
                  <!-- Add Class FOR Current step "current" OR Add Class FOR Complete step "active" -->
                  <a>Bienvenue</a>
                </li>
                <li :class="stepTwoData.state">
                  <a href="#" v-on:click="changeStep({action : 'StepTwo'})">Déclarant</a>
                </li>
                <li :class="stepThreeData.state">
                  <a href="#" v-on:click="changeStep({action : 'StepThree'})">Renseignements relatifs à la
                    construction</a>
                </li>
                <li :class="stepFourData.state">
                  <a href="#" v-on:click="changeStep({action : 'StepFour'})">Renseignements relatifs au sinistre</a>
                </li>
                <li :class="stepFiveData.state">
                  <a href="#">Signature</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="declaration-close-aedecp"><a v-on:click="this.$router.push('/assure')"><i
            class="fa-regular fa-xmark"></i>Quitter et abandonner la déclaration</a></div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 content-main-aedecp">
        <div class="top-bar-main-aedecp">
          <div class="tbar-left-aedecp">
            <div class="logo-main-mobile-aedecp"><a v-on:click="backStep"><img
                alt="" src="~@/assets/images/logo_main_hp.svg"></a></div>
            <div class="tbar-back-main-aedecp"><a v-on:click="backStep"><i
                class="fa-regular fa-arrow-left"></i></a></div>
            <div class="tbar-user-info-main-aedecp">
              <div class="tbar-user-text-aedecp">
                <h2>QBE</h2>
                <p>Contrat n° <span>{{ this.$store.state.contract.contract_number }}</span></p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="tbar-right-aedecp">
            <div class="declaration-numbers-aedecp"><span>{{ stepNumber }}/</span>5</div>
            <div class="mobile-menu-icon-aedecp">
              <div class="menu-toggle-btn-aedecp">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div v-if="showLoader" class="loader">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <StepOne v-if="stepOneData.show" type="assured" v-on:click="changeStep"/>
        <StepTwo v-if="stepTwoData.show" type="assured" v-on:click="changeStep"/>
        <StepThree v-if="stepThreeData.show" type="assured" v-on:click="changeStep"/>
        <StepFour v-if="stepFourData.show" type="assured" v-on:click="changeStep"/>
        <StepFive v-if="stepFiveData.show" type="assured" v-on:click="changeStep"/>
        <StepSix v-if="stepSixData.show" type="assured" v-on:click="changeStep"/>
      </div>
      <div class="clearfix"></div>
    </section>
    <!-- CONTAIN_END -->

  </div>
</template>

<script>
import StepOne from '@/components/declaration/StepOne.vue';
import StepTwo from '@/components/declaration/StepTwo.vue';
import StepThree from '@/components/declaration/StepThree.vue';
import StepFour from '@/components/declaration/StepFour.vue';
import StepFive from '@/components/declaration/StepFive.vue';
import StepSix from '@/components/declaration/StepSix.vue';
import {mapActions} from 'vuex';

export default {
  name: 'Declaration',
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix
  },
  data() {
    return {
      stepOneData: {
        state: 'current',
        show: true,
      },
      stepTwoData: {
        state: '',
        show: false,
      },
      stepThreeData: {
        state: '',
        show: false,
      },
      stepFourData: {
        state: '',
        show: false,
      },
      stepFiveData: {
        state: '',
        show: false,
      },
      stepSixData: {
        state: '',
        show: false,
      },
      stepNumber: 1,
      showLoader: false,
    }
  },
  methods: {
    ...mapActions('createSinistre', ['createSinister']),
    changeStep(step) {
      switch (step.action) {
        case('StepTwo') :
          this.stepOneData.state = "active";
          this.stepOneData.show = false;

          this.stepTwoData.state = "current";
          this.stepTwoData.show = true;

          this.stepNumber = 2;
          break;
        case('StepThree') :
          this.stepTwoData.state = "active";
          this.stepTwoData.show = false;

          this.stepThreeData.state = "current";
          this.stepThreeData.show = true;

          this.stepNumber = 3;
          break;
        case('StepFour') :
          this.stepThreeData.state = "active";
          this.stepThreeData.show = false;

          this.stepFourData.state = "current";
          this.stepFourData.show = true;

          this.stepNumber = 4;
          break;
        case('StepFive') :
          this.stepFourData.state = "active";
          this.stepFourData.show = false;

          this.stepFiveData.state = "current";
          this.stepFiveData.show = true;

          this.stepNumber = 5;
          break;
        case('End') :
          this.stepFiveData.state = "active";
          this.stepFiveData.show = false;
          this.showLoader = true;
          this.createSinister()
              .then(() => {
                    this.showLoader = false;
                    this.stepFiveData.state = "active";
                    this.stepFiveData.show = false;
                    this.stepSixData.show = true;
                  }
              );
          break;

      }
    },
    backStep() {
      if (this.stepSixData.show) {
        this.stepSixData.show = false;
        this.stepFourData.show = true;

        this.stepNumber = 4;

      } else if (this.stepFourData.show) {
        this.stepFourData.show = false;
        this.stepThreeData.show = true;
        this.stepNumber = 3;

      } else if (this.stepThreeData.show) {
        this.stepThreeData.show = false;
        this.stepTwoData.show = true;
        this.stepNumber = 2;

      } else if (this.stepTwoData.show) {
        this.stepTwoData.show = false;
        this.stepOneData.show = true;
        this.stepNumber = 1;

      } else if (this.stepOneData.show) {
        this.$router.push('/assure')
      }
    }
  }
}
</script>
<style scoped>
.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>