<template>   
    <div class="content-in-dbp user-page-up">
        <div class="user-top-up">
            <div class="user-top-left-up title-main-dbp">
                <h2>Utilisateurs</h2>
            </div>
            <div class="user-top-right-up">
                <a  v-on:click="openAddUser" id="openAddUser" class="btn default-btnH40-aehp default-Lblue-border-btn-aehp"><i class="far fa-user-plus"></i>Ajouter un utilisateur</a>
            </div>
        </div>
        <div class="user-bottom-up optiscroll">
            <div class="user-bottom-in-up">
                <div class="user-info-up">
                    <div class="user-list-up" v-for="(user, key) in usersList" :key="key">
                        <div class="user-name-up">
                            <div class="user-name-in-up">
                                <div class="user-icon-up">
                                    <div class="user-icon-in-up">{{user.initials}}</div>
                                </div>
                                <div class="user-data-up">
                                    <div class="user-surname-up">{{user.lastname}} {{user.firstname}}</div>
                                    <div class="user-des-up">{{user.job}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="user-email-up">
                            <a href="#">{{user.email}} </a>
                        </div>
                        <div class="user-action-up">
                            <div class="user-action-in-up">
                                <div class="user-edit-up"><a v-on:click="openEditUser(user)" href="javascript:void(0)" id="openEditUser"><i class="far fa-edit"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ADD_USER_START -->
        <div class="rcd-overlay-rcp" v-on:click="rcd"></div>
        <div class="rcd-information-rcp" id="add_user_up">
            <div class="rcd-close-rcp" v-on:click="rcd">
                <a href="javascript:void(0)"><i class="fal fa-times"></i></a>
            </div>
            <div class="rcd-info-box-rcp optiscroll">
                <div class="rcd-info-box-in-rcp">
                    <div class="rcd-info-box-pad-rcp">
                        <div class="rcd-title-rcp rcd-title-black-rcp">
                            <h2>Nouvel utilisateur</h2>
                        </div>
                        <div class="rdc-speaker-rcp">
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Nom <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" placeholder="Addin" v-model="create.lastname">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Prénom <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" placeholder="" v-model="create.firstname">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Poste <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" placeholder="" v-model="create.job">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Adresse e-mail <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" placeholder="" v-model="create.email">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rcd-info-bot-bar-rcp">
                <div class="rcd-info-bot-left-rcp">
                    <div class="cancel-btn-main-hp"><a href="#" v-on:click="rcd" class="btn default-btnH40-aehp default-Gray-border-btn-aehp">Annuler</a></div>
                </div>
                <div class="rcd-info-bot-right-rcp">
                    <div class="confirm-btn-main-hp"><a v-on:click="addUser" href="javascript:void(0)" class="btn default-btnH40-aehp default-green-btn-aehp"><i class="far fa-check-circle"></i>Créer le nouvel utilisateur</a></div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- ADD_USER_END -->
        <!-- EDIT_USER_START -->
        <div class="rcd-information-rcp" id="edit_user_up">
            <div class="rcd-close-rcp">
                <a href="javascript:void(0)" v-on:click="rcd"><i class="fal fa-times"></i></a>
            </div>
            <div class="rcd-info-box-rcp optiscroll">
                <div class="rcd-info-box-in-rcp">
                    <div class="rcd-info-box-pad-rcp">
                        <div class="rcd-title-rcp rcd-title-black-rcp">
                            <h2>Modifier l’utilisateur</h2>
                        </div>
                        <div class="rdc-speaker-rcp">
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Nom <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" v-model="update.lastname">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Prénom <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" v-model="update.firstname">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">POSTE <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" v-model="update.job">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Adresse e-mail <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" v-model="update.email" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-action-btn-dp">
                                <a href="#" v-on:click="resetPassword(user)" class="btn default-btnH40-aehp default-Lblue-border-btn-aehp"><i class="far fa-refresh"></i>Réinitialiser le mot de passe</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rcd-info-bot-bar-rcp edit-user-bot-bar-up">
                <div class="rcd-info-bot-left-rcp">
                    <div class="cancel-btn-main-hp"><a v-on:click="rcd" href="javascript:void(0)" class="btn default-btnH40-aehp default-Gray-border-btn-aehp">Annuler</a></div>
                </div>
                <div class="rcd-info-bot-right-rcp">
                    <div class="delet-btn-main-hp"><a data-toggle="modal" data-target="#userDeleteModal" class="btn default-btnH40-aehp default-Gborder-redT-btn-aehp"><i class="far fa-trash-can"></i>Supprimer l’utilisateur</a></div>
                    <div class="confirm-btn-main-hp"><a href="javascript:void(0)" v-on:click="updateUser()" class="btn default-btnH40-aehp default-green-btn-aehp"><i class="far fa-floppy-disk"></i>Enregistrer</a></div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- EDIT_USER_END -->
        <!-- USER_DELETE_MODAL_START -->
        <div class="modal fade" id="userDeleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
                <div class="modal-content modal-content-mp cancel-modal-content-mp"> <!-- cancel-modal-content-mp -->
                    <div class="modal-body modal-body-mp">
                        <div class="modal-title-mp">
                            <div class="modal-title-icon-mp">
                                <div class="modal-title-icon-in-mp">
                                    <i class="far fa-trash-alt"></i>
                                </div>
                            </div>
                            <div class="modal-title-text-mp">Supprimer cet utilisateur</div>
                        </div>
                        <div class="modal-details-mp">
                            <p>Vous êtes sur le point de supprimer cet utilisateur. - Ceci aura pour effet de supprimer toutes les données saisies associées à cette demande. Êtes vous certains de vouloir effectuer cette action ?</p>
                        </div>
                    </div>
                    <div class="modal-footer-mp">
                        <div class="cancel-btn-main-hp"><a href="javascript:void(0)" data-dismiss="modal" aria-label="Close" class="btn default-btnH40-aehp default-red-border-btn-aehp">Annuler</a></div>
                        <div class="confirm-btn-main-hp"><a href="javascript:void(0)" v-on:click="deleteUser" data-dismiss="modal" aria-label="Close" class="btn default-btnH40-aehp default-red-btn-aehp"><i class="far fa-trash-can"></i>Supprimer</a></div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- USER_DELETE_MODAL_END -->
    </div>
</template>

<script>
import $ from 'jquery';
export default {
    name: 'Dashboard',
    data(){
        return{
            create:{
                lastname : 'Jean',
                firstname: 'Dupont',
                job : 'Gestionnaire',
                email : 'jean@gestionnaire.fr',
            },
            update : {
                lastname : 'Jean',
                firstname: 'Dupont',
                job : 'Gestionnaire',
                email : 'jean@gestionnaire.fr',
            },
            usersList : null,
        }
    },
    beforeCreate(){
        this.$store.dispatch('users/find')
        .then((res) => {
            this.usersList = res
        })
    },
    methods: {
        openAddUser(){
            $(".rcd-overlay-rcp").fadeToggle();
            $("#add_user_up").toggleClass("open");
        },
        openEditUser(data){
            this.update = data;
            $(".rcd-overlay-rcp").fadeToggle();
            $("#edit_user_up").toggleClass("open");
        },
        rcd(){
            $(".rcd-overlay-rcp").fadeOut();
            $("#add_user_up").removeClass("open");
            $("#edit_user_up").removeClass("open");
        },
        addUser(){
            this.$store.dispatch('users/create', this.create)
            .then(() => {
                this.$emit('updateUsers');
            })
        },
        deleteUser(){
            this.$store.dispatch('users/delete', this.update.id)
            .then(() => {
                this.$emit('updateUsers');
            })
        },
        resetPassword(){
            this.$store.dispatch('users/resetPassword', this.update.id)
            .then(() => {
                this.$emit('updateUsers');
            })
        },
        updateUser(){
            this.$store.dispatch('users/update', this.update)
            .then(() => {
                this.$emit('updateUsers');
            })
        }
    }
}
</script>
