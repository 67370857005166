<template>
  <div class="declaring-block-main-aedecp optiscroll stepFive">
    <div class="declaring-block-in-aedecp">
      <div class="common-title-main-aehp common-title-left-aehp">
        <h2>Signature</h2>
      </div>
      <div class="declaring-form-main-aedecp signature-form-main-aedecp">
        <div class="default-form-field1-aedecp">
          <div class="default-form-field1-in-aedecp">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
              <div class="defa-form-field-check-aedecp custom-control custom-checkbox">
                <input id="contact_check1" v-model="sign.check" :value="true" class="custom-control-input"
                       type="checkbox">
                <label class="custom-control-label" for="contact_check1">J'accepte que les informations me concernant
                  soient exploitées dans le cadre de la demande de contact et de la relation commerciale qui en
                  découle.</label>
              </div>
            </div>
          </div>
        </div>
        <div class="default-form-field1-aedecp">
          <div class="default-form-field1-in-aedecp">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
              <div class="default-form-field1-tit-aedecp">Fait à</div>
              <div class="defa-form-field-Tbox-aedecp">
                <input v-model="sign.made_in" placeholder="" type="text" @change="(e) => remove(e.target)">
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
              <div class="default-form-field1-tit-aedecp">Date</div>
              <div class="defa-form-field-Tbox-aedecp input-group defa-form-Dtpicker-aedecp date form_date">
                <input v-model="sign.date" class="cal-size " type="date" @change="(e) => remove(e.target)">
              </div>
            </div>
          </div>
        </div>
        <div class="default-note-text-aedecp default-note-text2-aedecp">
          <p>*Vous pouvez en obtenir la communication ainsi que leur éventuelle rectification auprès de : AGEMI –
            Service sinistres – 11 rue de Rochechouart, 75009 PARIS</p>
        </div>
      </div>
      <div class="default-form-btn-bar-aedecp">
        <div class="default-form-btn-aedecp">
          <a class="btn default-btn-aehp default-green-btn-aehp" v-on:click="redirect">Valider la déclaration</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import $ from 'jquery';

export default {
  name: 'StepFive',
  props: ['type'],
  data() {
    return {
      sign: {
        check: null,
        made_in: null,
        date: null,
      }
    }
  },
  methods: {
    ...mapActions('createSinistre', ['addSign']),
    redirect() {
      if (this.type === 'assured') {
        let numberInput = 3;
        let numberGoodInput = 0;
        $(".stepFive input:text, .stepFive input[type='date']").each(function () {
          if (!$(this).val()) {
            $(this).addClass('errorInput');
          } else {
            numberGoodInput++
            $(this).removeClass('errorInput');
          }
        });
        if (this.sign.check) {
          $('.custom-control-label').removeClass('errorColor')
          numberGoodInput++;
        } else {
          $('.custom-control-label').addClass('errorColor')
        }
        if (numberInput === numberGoodInput) {
          this.addSign(this.sign);
          this.$emit('click', {action: 'End'});
        }
      } else {
        this.addSign(this.sign);
        this.$emit('click', {action: 'StepSixAdmin'});
      }
    },
    remove(e) {
      $(e).removeClass('errorInput');
    }
  }

}
</script>
<style scoped>
.errorInput {
  border: solid 2px #f03737;
}

.errorColor {
  color: #f03737;
}
</style>