<template>   
    <div class="content-in-dbp user-page-up">
        <div class="user-top-up trames-top-tnp">
            <div class="user-top-left-up title-main-dbp">
                <h2>Trames</h2>
            </div>
            <div class="trames-menu-tnp">
                <div class="list-filter-left-lip">
                    <ul>
                        <li><a  v-on:click="updateList('notification')" id="notification" class="listType active">Notifications</a></li>
                        <li><a v-on:click="updateList('SAV')" id="SAV"  class="listType">Process SAV</a></li>
                        <li><a  v-on:click="updateList('orderMission')" id="orderMission"  class="listType">Ordre de mission</a></li>
                    </ul>
                </div>
            </div>
            <div class="user-top-right-up" >
            <!-- <div class="user-top-right-up" v-if="actif === 'notification'"> -->
                <a href="javascript:void(0)" v-on:click="openAdd" id="openAddNotiTrames" class="btn default-btnH40-aehp default-Lblue-border-btn-aehp"><i class="far fa-envelopes-bulk"></i>Ajouter une notification</a>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="user-bottom-up optiscroll">
            <div class="user-bottom-in-up">
                <div class="user-info-up">
                    <div class="Tnotifi-list-tnp" v-for="(trame, key) in data" :key="key">
                        <div class="Tnotifi-info-text-tnp">
                            <div class="Tnotifi-info-text-tnp">
                                <div class="Tnotifi-info-Ttitle-tnp">{{trame.title}}</div>
                                <div v-html="trame.extract"></div>
                            </div>
                        </div>
                        <div class="user-action-up">
                            <div class="user-action-in-up">
                                <div v-on:click="openUpdate({id : trame.id,title : trame.title, content : trame.content})" class="user-edit-up"><a href="javascript:void(0)" id="openEditNotiTrames"><i class="far fa-edit"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ADD_NOTIFICATIONS_START -->
        <div v-on:click="closeAdd" class="rcd-overlay-rcp" ></div>
        <div class="rcd-information-rcp" id="Trams_Notification_tnp">
            <div class="rcd-close-rcp">
                <a v-on:click="closeAdd" href="javascript:void(0)"><i class="fal fa-times"></i></a>
            </div>
            <div class="rcd-info-box-rcp Tadd-notifi-info-box-tnp">
                <div class="rcd-info-box-in-rcp">
                    <div class="rcd-info-box-pad-rcp">
                        <div class="rdc-speaker-rcp">
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">NOM DE LA TRAME</div>
                                        <div class="dos-info-field-input-dp">
                                            <input type="text" v-model="contentAdd.title">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <QuillEditor theme="snow"  v-model:content="contentAdd.html" contentType="html" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="rcd-info-bot-bar-rcp">
                <div class="rcd-info-bot-left-rcp">
                    <div v-on:click="closeAdd" class="cancel-btn-main-hp"><a href="#" class="btn default-btnH40-aehp default-Gray-border-btn-aehp">Annuler</a></div>
                </div>
                <div class="rcd-info-bot-right-rcp">
                    <div v-on:click="create" class="confirm-btn-main-hp"><a href="javascript:void(0)" class="btn default-btnH40-aehp default-green-btn-aehp"><i class="far fa-floppy-disk"></i>Enregistrer</a></div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- ADD_NOTIFICATIONS_END -->
        <div class="green-box-main-top-dp">
            <div class="green-box-main-dp" style="display:none">
                <div class="error-text-lp"><i class="far fa-check-circle"></i>{{this.succesMessage}}</div>
                <div class="error-box-close-lp"><a href="javascript:void(0)"><i class="fal fa-times"></i></a></div>
            </div>
        </div>
        <!-- EDIT_NOTIFICATIONS_START -->
        <div class="rcd-information-rcp" id="Edit_Trams_Notification_tnp">
            <div v-on:click="closeUpdate" class="rcd-close-rcp">
                <a href="javascript:void(0)"><i class="fal fa-times"></i></a>
            </div>
            <div class="rcd-info-box-rcp optiscroll">
                <div class="rcd-info-box-in-rcp">
                    <div class="rcd-info-box-pad-rcp">
                        <div class="rcd-title-rcp rcd-title-black-rcp">
                            <h2>{{contentEdit.title}}</h2>
                        </div>
                        <div class="rdc-speaker-rcp">
                            <QuillEditor :key="this.contentEdit.update" toolbar="full" theme="snow" v-model:content="contentEdit.html" contentType="html" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="rcd-info-bot-bar-rcp">
                <div class="rcd-info-bot-left-rcp">
                    <div v-on:click="closeUpdate" class="cancel-btn-main-hp"><a href="#" class="btn default-btnH40-aehp default-Gray-border-btn-aehp">Annuler</a></div>
                </div>
                <div class="rcd-info-bot-right-rcp">
                    <div class="delet-btn-main-hp"><a href="javascript:void(0)" data-toggle="modal" data-target="#notifiDeleteModal" class="btn default-btnH40-aehp default-Gborder-redT-btn-aehp"><i class="far fa-trash-can"></i>Supprimer</a></div>
                    <div v-on:click="update" class="confirm-btn-main-hp"><a href="javascript:void(0)" class="btn default-btnH40-aehp default-green-btn-aehp"><i class="far fa-floppy-disk"></i>Enregistrer</a></div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- EDIT_NOTIFICATIONS_END -->
        <!-- DELETE_MODAL_START -->
        <div class="modal fade" id="notifiDeleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
                <div class="modal-content modal-content-mp cancel-modal-content-mp"> <!-- cancel-modal-content-mp -->
                    <div class="modal-body modal-body-mp">
                        <div class="modal-title-mp">
                            <div class="modal-title-icon-mp">
                                <div class="modal-title-icon-in-mp">
                                    <i class="far fa-trash-alt"></i>
                                </div>
                            </div>
                            <div class="modal-title-text-mp">Supprimer cette Notification</div>
                        </div>
                        <div class="modal-details-mp">
                            <p>Vous êtes sur le point de supprimer cette Notification. - Ceci aura pour effet de supprimer toutes les données saisies associées à cette demande. Êtes vous certains de vouloir effectuer cette action ?</p>
                        </div>
                    </div>
                    <div class="modal-footer-mp">
                        <div class="cancel-btn-main-hp"><a href="javascript:void(0)" data-dismiss="modal" aria-label="Close" class="btn default-btnH40-aehp default-red-border-btn-aehp">Annuler</a></div>
                        <div class="confirm-btn-main-hp"><a v-on:click="deleteTrame" data-dismiss="modal" aria-label="Close" class="btn default-btnH40-aehp default-red-btn-aehp"><i class="far fa-trash-can"></i>Supprimer</a></div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- DELETE_MODAL_END -->
    </div>
</template>

<script>
import $ from 'jquery';
import { QuillEditor } from '@vueup/vue-quill';
export default {
    name: 'Trames',
    components:{
        QuillEditor,
    },
    data(){
        return{
            contentAdd : {
                title : null,
                html : null
            },
            contentEdit : {
                id : null,
                title : null,
                html : null,
                update : 0
            },
            actif : 'notification',
            data : null,
            succesMessage : null,
        }
    },
    beforeCreate(){
        this.$store.dispatch('wefts/find', {type : 'notification'})
        .then((res) => {
            this.data = res;
            let i = 0;
            for(const element of this.data){
                this.data.extract = element.content.outerHTML;
                const end = element.content.length > 40 ? ' ...' : '';
                this.data[i].extract = element.content.replace(/<[^>]+>/g, '').substring(0,40) + end;
                console.log(this.data.extract);
                i++
            }
        })
        .catch(err => console.log(err));
    },
    methods: {
        successMessage(){
            this.$emit('update');
            $(".green-box-main-dp").fadeIn();
            setTimeout(function(){ 
                $(".green-box-main-dp").fadeOut();
            }, 3000);
        },
        openAdd(){
            $(".rcd-overlay-rcp").fadeToggle();
            $("#Trams_Notification_tnp").toggleClass("open");
        },
        closeAdd(){
            $(".rcd-overlay-rcp").fadeOut();
            $("#Trams_Notification_tnp").removeClass("open");
            $("#Edit_Trams_Notification_tnp").removeClass("open");
        },
        create(){
            if(!this.contentAdd.title || !this.contentAdd.html){
                alert('empty field');
            }
            else{
                this.$store.dispatch('wefts/create', {...this.contentAdd, type: this.actif})
                .then((res) => {
                    console.log(res);
                    this.closeAdd();
                    this.succesMessage = 'Trame enregistrée';
                    this.successMessage();
                    this.$emit('update');
                })
                .catch(err => console.log(err));
            }
        },
        openUpdate(data){
            this.contentEdit.update = this.contentEdit.update+ 1;
            this.contentEdit.title = data.title;
            this.contentEdit.id = data.id
            this.contentEdit.html = data.content; 
            $(".rcd-overlay-rcp").fadeToggle();
            $("#Edit_Trams_Notification_tnp").toggleClass("open");
        },
        closeUpdate(){
            $(".rcd-overlay-rcp").fadeOut();
            $("#Trams_Notification_tnp").removeClass("open");
            $("#Edit_Trams_Notification_tnp").removeClass("open");
        },
        update(){
            this.$store.dispatch('wefts/update', {...this.contentEdit })
            .then((res) => {
                console.log(res);
                this.succesMessage = 'Trame modifée';
                this.successMessage();
                this.closeUpdate();
                this.$emit('update');
            })
            .catch(err => console.log(err));
        },
        deleteTrame(){
            this.$store.dispatch('wefts/delete', {id : this.contentEdit.id})
            .then((res) => {
                console.log(res);
                this.succesMessage = 'Trame supprimée';
                this.successMessage();
                this.closeUpdate();
            })
        },
        updateList(type){
            this.actif = type;
            $('.listType').removeClass('active');
            $('#'+type).toggleClass('active');
            this.$store.dispatch('wefts/find', {type})
            .then((res) => {
                this.data = res;
                let i = 0;
                for(const element of this.data){
                    this.data.extract = element.content.outerHTML;
                    const end = element.content.length > 40 ? ' ...' : '';
                    this.data[i].extract = element.content.replace(/<[^>]+>/g, '').substring(0,40) + end;
                    console.log(this.data.extract);
                    i++
                }
            })
            .catch(err => console.log(err));
        }
    }
}
</script>

<style>
.user-page-up{
    overflow: scroll;
}
.ql-editor{
    min-height: 100px!important;
}
</style>