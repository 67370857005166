<template>
  <div class="wrapper">
    <!-- CONTAIN_START -->
    <section id="contain">
      <div class="sidebar-main-aedecp">
        <div class="logo-main-aedecp"><a href="#"><img alt="" src="~@/assets/images/logo_main_white_hp.svg"></a></div>
        <div class="sidebar-content-aedecp optiscroll">
          <div class="sidebar-content-in-aedecp">
            <div class="decl-steps-main-aedecp">
              <ul>
                <!--                <li :class="stepOneData.state">-->
                <!--                  <a href="#" v-on:click="changeStep({action : 'StepOne'})">Bienvenue</a>-->
                <!--                </li>-->
                <li :class="stepTwoData.state">
                  <a href="#" v-on:click="changeStep({action : 'StepTwo'})">Déclarant</a>
                </li>
                <li :class="stepThreeData.state">
                  <a href="#" v-on:click="changeStep({action : 'StepThree'})">Renseignements relatifs à la
                    construction</a>
                </li>
                <li :class="stepFourData.state">
                  <a href="#" v-on:click="changeStep({action : 'End'})">Renseignements relatifs au sinistre</a>
                </li>
                <!--                <li :class="stepFiveData.state">-->
                <!--                  <a href="#" v-on:click="changeStep({action : 'StepSixAdmin'})">Signature</a>-->
                <!--                </li>-->

              </ul>
            </div>
          </div>
        </div>
        <div class="declaration-close-aedecp"><a v-on:click="this.$router.push('/gestionnaire')"><i
            class="fa-regular fa-xmark"></i>Quitter et abandonner la déclaration</a></div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 content-main-aedecp">
        <div class="top-bar-main-aedecp">
          <div class="tbar-left-aedecp">
            <div class="logo-main-mobile-aedecp"><a href=# v-on:click="backStep">
              <img alt="" src="~@/assets/images/logo_main_hp.svg"></a>
            </div>
            <div class="tbar-back-main-aedecp"><a href=# v-on:click="backStep"><i
                class="fa-regular fa-arrow-left"></i></a></div>
            <div class="tbar-user-info-main-aedecp">
              <div class="tbar-user-text-aedecp">
                <h2>QBE</h2>
                <p>Contrat n° <span>{{ this.$store.state.contract.contract_number }}</span></p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="tbar-right-aedecp">
            <div class="declaration-numbers-aedecp"><span>{{ stepNumber }}/</span>5</div>
            <div class="mobile-menu-icon-aedecp">
              <div class="menu-toggle-btn-aedecp">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
        </div>
        <!--        <StepOne v-if="stepOneData.show" v-on:click="changeStep"/>-->
        <StepTwo v-if="stepTwoData.show" v-on:click="changeStep"/>
        <StepThree v-if="stepThreeData.show" v-on:click="changeStep"/>
        <StepFour v-if="stepFourData.show" :new='newSinister' v-on:click="changeStep"/>
        <!--        <StepFive v-if="stepFiveData.show" v-on:click="changeStep"/>-->
        <StepSixAdmin v-if="stepSixAdminData.show" :res="resData" v-on:click="changeStep"/>
      </div>
      <div class="clearfix"></div>
    </section>
    <!-- CONTAIN_END -->

  </div>
</template>

<script>
// import StepOne from '@/components/declaration/StepOne.vue';
import StepTwo from '@/components/declaration/StepTwo.vue';
import StepThree from '@/components/declaration/StepThree.vue';
import StepFour from '@/components/declaration/StepFour.vue';
// import StepFive from '@/components/declaration/StepFive.vue';
import StepSixAdmin from '@/components/declaration/finalStepAdmin.vue';
import {mapActions} from 'vuex';

export default {
  name: 'Declaration',
  props: ['newSinister'],
  components: {
    // StepOne,
    StepTwo,
    StepThree,
    StepFour,
    // StepFive,
    StepSixAdmin
  },
  data() {
    return {
      // stepOneData: {
      //   state: '',
      //   show: true,
      // },
      stepTwoData: {
        state: 'current',
        show: true,
      },
      stepThreeData: {
        state: '',
        show: false,
      },
      stepFourData: {
        state: '',
        show: false,
      },
      // stepFiveData: {
      //   state: '',
      //   show: false,
      // },
      stepSixAdminData: {
        state: '',
        show: false,
      },
      stepNumber: 1,
      resData: {},
    }
  },
  methods: {
    ...mapActions('createSinistre', ['createSinister']),
    changeStep(step) {
      switch (step.action) {
        case('StepTwo') :
          this.stepOneData.state = "active";
          this.stepOneData.show = false;

          this.stepTwoData.state = "current";
          this.stepTwoData.show = true;

          this.stepNumber = 2;
          break;
        case('StepThree') :
          this.stepTwoData.state = "active";
          this.stepTwoData.show = false;

          this.stepThreeData.state = "current";
          this.stepThreeData.show = true;

          this.stepNumber = 3;
          break;
        case('StepFour') :
          this.stepThreeData.state = "active";
          this.stepThreeData.show = false;

          this.stepFourData.state = "current";
          this.stepFourData.show = true;

          this.stepNumber = 4;
          break;
        case('StepFive') :
          this.stepFourData.state = "active";
          this.stepFourData.show = false;

          this.stepFiveData.state = "current";
          this.stepFiveData.show = true;

          this.stepNumber = 5;
          break;
        case('End') :
          this.stepFourData.state = "active";
          this.stepFourData.show = false;

          this.stepSixAdminData.state = "current";
          this.stepSixAdminData.show = true;

          this.stepNumber = 5;
          this.showLoader = true;
          this.createSinister()
              .then((res) => {
                    this.resData = res;
                    this.showLoader = false;
                    this.stepFourData.state = "active";
                    this.stepFourData.show = false;
                    this.stepSixAdminData.state = "current";
                    this.stepSixAdminData.show = true;
                  }
              ).catch((error)=> {
                console.log(error)
              });
          break;
      }
    },
    backStep() {
      if (this.stepSixAdminData.show) {
        this.stepSixAdminData.show = false;
        this.stepFourData.show = true;
        this.stepSixAdminData.state = "";
        this.stepFourData.state = "current";
        this.stepNumber = 5;

        // } else if (this.stepFiveData.show) {
        //   this.stepFiveData.show = false;
        //   this.stepFourData.show = true;
        //   this.stepFiveData.state = "";
        //   this.stepFourData.state = "current";
        //   this.stepNumber = 4;

      } else if (this.stepFourData.show) {
        this.stepFourData.show = false;
        this.stepThreeData.show = true;
        this.stepFourData.state = "";
        this.stepThreeData.state = "current";
        this.stepNumber = 3;

      } else if (this.stepThreeData.show) {
        this.stepThreeData.show = false;
        this.stepTwoData.show = true;
        this.stepThreeData.state = "";
        this.stepTwoData.state = "current";
        this.stepNumber = 2;

        // } else if (this.stepTwoData.show) {
        //   this.stepTwoData.show = false;
        //   this.stepOneData.show = true;
        //   this.stepTwoData.state = "";
        //   this.stepOneData.state = "current";
        //   this.stepNumber = 1;

      } else if (this.stepTwoData.show) {
        this.$router.push('/gestionnaire')
      }
    }
  },
}
</script>